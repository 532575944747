<ax-avatar-edit
  [name]="getName()"
  [avatarURL]="form?.value?.value"
  [update]="updateAvatar"
  [canDelete]="form?.value.value"
  [delete]="updateAvatar"
  [type]="isIndividual ? AvatarType.INDIVIDUAL : AvatarType.INDIVIDUAL"
  [readonly]="readonly"
></ax-avatar-edit>
<mat-hint
  [ngClass]="{collapsed}"
  (click)="collapsed = !collapsed"
  class="hint collapsible"
  align="end"
  #hintElement
>
  <div>
    <ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon>
  </div>
  <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
</mat-hint>
