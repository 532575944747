<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="PropertyType.ORGANISATION_TYPE | propertyIcon"
></ax-icon>
<mat-form-field
  appearance="outline"
  class="c-input"
  [ngClass]="{readonly: readonly}"
  style="width: 100%"
>
  <mat-label>
    {{ customLabel === '' ? (type | propertyTypeLabel) : customLabel
    }}<span *ngIf="required">*</span>
  </mat-label>
  <mat-select
    disableOptionCentering
    [placeholder]="PropertyType.ORGANISATION_TYPE | propertyTypeLabel"
    [formControl]="form.get('value')"
  >
    <mat-option *ngFor="let type of types" [value]="type">
      {{ 'property.organisation-type.' + type | translate }}
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="hint" align="end">
    <span>{{ hint }}</span>
  </mat-hint>
  <mat-error>
    <ax-icon name="alert-circle"></ax-icon>
    {{
      'property.validation.required'
        | translate: {propertyType: 'property.CATEGORY.label' | translate}
    }}
  </mat-error>
  <ax-clear-button *ngIf="form.value && !readonly" [form]="form" matSuffix></ax-clear-button>
</mat-form-field>
