import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AvatarType} from '@aztrix/components/avatar';
import {getLatestOrDefaultLanguage} from '@aztrix/helpers';
import {ProposalRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-proposal-cell-view',
  templateUrl: './proposal-cell-view.component.html',
  styleUrls: ['./proposal-cell-view.component.scss', '../cell-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalCellViewComponent {
  @Input() proposal: ProposalRepresentation;
  @Input() languageCode?: string;

  AvatarType = AvatarType;

  get avatarSource() {
    return getLatestOrDefaultLanguage(this.proposal, this.languageCode)?.avatar;
  }
}
