<ax-file-edit
  [form]="form"
  [name]="name"
  [hint]="hint"
  [autofocus]="autofocus"
  [customLabel]="customLabel"
  [required]="required"
  [autocompleteAttr]="autocompleteAttr"
  [showErrors]="showErrors"
  [showIcon]="showIcon"
  [acceptTypes]="acceptTypes"
  [maxFileSize]="maxFileSize"
  [uploadLink]="uploadLink"
  [required]="required"
  [preview]="preview"
  [previewUrl]="previewUrl"
  [demo]="demo"
  [readonly]="readonly"
></ax-file-edit>
