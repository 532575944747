<ng-container [ngSwitch]="true">
  <ax-address-edit
    *ngSwitchCase="property | propertyIsAddressRelated"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [autocompleteProperties]="autocompleteProperties"
    [required]="required"
    [showIcon]="showIcon"
    [showErrors]="showErrors"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-address-edit>
  <ax-gender-edit
    *ngSwitchCase="property | isOfType: PropertyType.GENDER"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [showErrors]="showErrors"
    [readonly]="readonly"
  ></ax-gender-edit>
  <ax-social-edit
    *ngSwitchCase="property | propertyIsSocialRelated"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [required]="required"
    [matAutocomplete]="auto.matAutocomplete"
    [autocompleteAttr]="autocompleteAttr"
    [autocompleteProperties]="autocompleteProperties"
    [autocomplete]="autocomplete"
    [showIcon]="showIcon"
    [showErrors]="showErrors"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-social-edit>
  <ax-legalid-edit
    *ngSwitchCase="property | isOfType: PropertyType.LEGAL_ID"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [matAutocomplete]="auto.matAutocomplete"
    [autocompleteAttr]="autocompleteAttr"
    [autocompleteProperties]="autocompleteProperties"
    [autocomplete]="autocomplete"
    [showIcon]="showIcon"
    [showErrors]="showErrors"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-legalid-edit>
  <ax-date-property-edit
    *ngSwitchCase="property | propertyIsDateRelated"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [showErrors]="showErrors"
    [readonly]="readonly"
  ></ax-date-property-edit>
  <ax-avatar-property-edit
    *ngSwitchCase="property | isOfType: PropertyType.AVATAR"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [customLabel]="customLabel"
    [required]="required"
    [hint]="hint"
    [isIndividual]="isIndividual"
    [readonly]="readonly"
  ></ax-avatar-property-edit>
  <ax-opening-hours-edit
    *ngSwitchCase="property | isOfType: PropertyType.OPENING_HOURS"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [isProposalSubscribeForm]="isProposalSubscribeForm"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-opening-hours-edit>
  <ax-file-edit
    *ngSwitchCase="property | isOfType: PropertyType.FILE"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-file-edit>
  <ax-document-edit
    *ngSwitchCase="property | propertyIsDocumentRelated"
    [form]="form"
    [showIcon]="showIcon"
    [required]="required"
    [readonly]="readonly"
  ></ax-document-edit>
  <ax-image-edit
    *ngSwitchCase="property | isOfType: PropertyType.IMAGE"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-image-edit>
  <ax-category-edit
    *ngSwitchCase="property | isOfType: PropertyType.CATEGORY"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-category-edit>
  <ax-organisation-type-edit
    *ngSwitchCase="property | isOfType: PropertyType.ORGANISATION_TYPE"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-organisation-type-edit>
  <ax-name-edit
    *ngSwitchCase="property | isOfType: PropertyType.NAME"
    [form]="form.get('properties')"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [readonly]="readonly"
  ></ax-name-edit>
  <ax-country-edit
    *ngSwitchCase="property | isOfType: PropertyType.COUNTRY"
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-country-edit>
  <ax-default-edit
    *ngSwitchDefault
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [customLabel]="customLabel"
    [required]="required"
    [matAutocomplete]="auto.matAutocomplete"
    [autocompleteAttr]="autocompleteAttr"
    [showIcon]="showIcon"
    [showErrors]="showErrors"
    [autocompleteProperties]="autocompleteProperties"
    [prefix]="prefix"
    [postfix]="postfix"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
    [icon]="icon"
  ></ax-default-edit>
</ng-container>

<ax-edit-autocompletion #auto [disabled]="!autocomplete"></ax-edit-autocompletion>
