<ax-icon *ngIf="icon" class="icon type-icon" [name]="icon"></ax-icon>
<ax-rich-text-theme>
  <mat-form-field
    class="c-input"
    [ngClass]="{'remove-subtext': !errorsTemplate && !hint, readonly: readonly}"
    appearance="outline"
    floatLabel="always"
  >
    <mat-label *ngIf="label"
      ><span>{{ label }}</span></mat-label
    >
    <aztrix-quill
      matInput
      axAutofocus
      [formControl]="richTextForm"
      [placeholder]="placeholder"
      [readOnly]="readonly"
      (onEditorCreated)="editorChanged($event)"
      (focus)="focused.emit()"
    >
      <ng-content></ng-content>
    </aztrix-quill>
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <ax-clear-button
      matSuffix
      [form]="form"
      *ngIf="hasValue && !form.disabled && !readonly"
    ></ax-clear-button>
    <mat-error *ngIf="errorsTemplate">
      <ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template>
    </mat-error>
  </mat-form-field>
</ax-rich-text-theme>
