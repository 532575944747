<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #ready>
  <ng-container [ngSwitch]="type">
    <ax-boolean-edit
      *ngSwitchCase="'BOOLEAN'"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [readonly]="readonly"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-boolean-edit>
    <ax-textarea-edit
      *ngSwitchCase="'TEXT_AREA'"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [matAutocomplete]="matAutocomplete"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [readonly]="readonly"
      (focused)="focused.emit()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-textarea-edit>
    <ax-url-edit
      *ngSwitchCase="'URL'"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [matAutocomplete]="matAutocomplete"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [readonly]="readonly"
      [enableValidation]="enableValidation"
      [prefix]="prefix"
      (focused)="focused.emit()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-url-edit>
    <ax-phone-edit
      *ngSwitchCase="'PHONE'"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [matAutocomplete]="matAutocomplete"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [readonly]="readonly"
      (focused)="focused.emit()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-phone-edit>
    <ax-rich-text-edit
      *ngSwitchCase="'RICH_TEXT'"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [matAutocomplete]="matAutocomplete"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [readonly]="readonly"
      (quillEditorChange)="quillEditorCreated($event)"
      (focused)="focused.emit()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-rich-text-edit>
    <ax-date-edit
      *ngSwitchCase="'DATE'"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [matAutocomplete]="matAutocomplete"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [readonly]="readonly"
      (focused)="focused.emit()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-date-edit>
    <ax-text-edit
      *ngSwitchDefault
      [inputType]="inputType || 'text'"
      [numberConfig]="numberConfig"
      [form]="form"
      [name]="name"
      [label]="label"
      [floatLabel]="floatLabel"
      [placeholder]="placeholder"
      [icon]="icon"
      [hint]="hint"
      [autofocus]="autofocus"
      [required]="required"
      [matAutocomplete]="matAutocomplete"
      [autocompleteAttr]="autocompleteAttr"
      [errorsTemplate]="errorsTemplate"
      [prefix]="prefix"
      [postfix]="postfix"
      [readonly]="readonly"
      (focused)="focused.emit()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ax-text-edit>
  </ng-container>
</ng-template>

<div *ngIf="!form; else ready" class="loading">&nbsp;</div>
