<ax-time-edit
  [name]="name + ' from'"
  [control]="fromControl"
  [label]="'label.from' | translate"
  [readonly]="readonly"
></ax-time-edit>
<span>-</span>
<ax-time-edit
  [name]="name + ' to'"
  [control]="toControl"
  [label]="'label.to' | translate"
  [readonly]="readonly"
></ax-time-edit>
