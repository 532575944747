<mat-form-field
  disabled
  class="c-input"
  [ngClass]="{'remove-subtext': !hasError && !hint && !uploading, readonly: readonly}"
  appearance="outline"
  floatLabel="always"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    *ngIf="nameControl"
    matInput
    [formControl]="nameControl"
    [placeholder]="types"
    readonly
    (click)="selectFile()"
  />
  <mat-hint *ngIf="uploading">{{ 'file-edit.uploading' | translate }}</mat-hint>
  <mat-hint
    *ngIf="!uploading"
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
  >
    <div>
      <ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon>
    </div>
    <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
  </mat-hint>
  <mat-error *ngIf="fileUploadFailed">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    {{ 'file-edit.upload-failed' | translate }}
  </mat-error>
  <mat-error *ngIf="fileTypeError && !fileUploadFailed">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    {{ 'file-edit.error.incorrect-file-type' | translate: {types: fileTypeError.expectedTypes} }}
  </mat-error>
  <mat-error *ngIf="fileSizeError && !fileUploadFailed && !fileTypeError">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    {{
      ('file-edit.error.max-file-size' | translate) +
        ' ' +
        toByteNotation(fileSizeError.max) +
        ' (' +
        toByteNotation(fileSizeError.size) +
        ')'
    }}
  </mat-error>
  <mat-error *ngIf="requiredError && !fileSizeError && !fileUploadFailed && !fileTypeError">
    {{ 'property.validation.required' | translate: {propertyType: label} }}
  </mat-error>
  <ax-image-preview-button
    *ngIf="preview && !empty"
    class="preview"
    matSuffix
    [source]="demo ? demoUrl : (source$ | async)"
    [name]="nameControl?.value"
  ></ax-image-preview-button>
  <button
    *ngIf="nameControl?.enabled && nameControl?.value"
    matSuffix
    axButton
    icon-button
    type="button"
    (click)="clearValue($event)"
    [ariaLabel]="'label.clear' | translate"
  >
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
</mat-form-field>

<button
  class="clip"
  mat-mini-fab
  (click)="selectFile()"
  type="button"
  [ariaLabel]="'label.upload' | translate"
  [disabled]="uploading || nameControl?.disabled"
>
  <ax-icon class="icon paperclip" name="paperclip"></ax-icon>
</button>

<input
  hidden
  name="file"
  type="file"
  (change)="fileSelected($event)"
  [attr.accept]="accept"
  #fileSelect
/>
