import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {
  createRangeForm,
  createRangesForm,
  OpeningDates,
  OpeningDay,
  OpeningDayLine,
  OpeningHoursSerializer,
} from '@aztrix/helpers';
import {Locale} from '@aztrix/models';
import {LOCALE} from '@aztrix/translate';
import {FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';
import {BehaviorSubject, startWith, Subscription} from 'rxjs';

@Component({
  selector: 'ax-days-combined-edit',
  templateUrl: 'days-combined-edit.component.html',
  styleUrls: ['days-combined-edit.component.scss'],
})
export class DaysCombinedEditComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() form: FormGroup<any>;
  @Input() name: string;
  @Input() cleared = false;
  @Input() readonly = false;
  @Output() add = new EventEmitter<FormGroup<any>>();
  @Output() remove = new EventEmitter<{group: FormGroup<any>; range: FormGroup<any>}>();

  @HostBinding('class.special') special = false;
  @MediaQuery('ax-day-range-edit:(min-width: 39.375em)') @HostBinding('class.large') large = false;

  subscriptions = new Subscription();
  combinedOpeningDaysSubscriptions = new Subscription();

  combinedOpeningDays: {combined: FormGroup<any>; originals: FormGroup<any>[]}[] = [];

  constructor(
    private _resize: ObserveResizeService,
    private _elementRef: ElementRef,
    private _changeDetector: ChangeDetectorRef,
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.subscriptions.unsubscribe();
      this.subscriptions = new Subscription();

      this.subscriptions.add(
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((value) => {
          const currentOpeningDates = this.combinedOpeningDays.flatMap((cod) => {
            const result: OpeningDay[] = [];
            for (const original of cod.originals) {
              result.push(<OpeningDay>{
                ...(<OpeningDayLine>cod.combined.value),
                days: undefined,
                day: original.value.day,
              });
            }
            return result;
          });

          if (
            OpeningHoursSerializer.serialize([<OpeningDates>value]) !==
            OpeningHoursSerializer.serialize(<OpeningDates[]>[
              {
                ...value,
                dates: [],
                openingDays: currentOpeningDates,
              },
            ])
          ) {
            this.combinedOpeningDays = this.openingDaysForm.controls.reduce(
              (array, openingDay: FormGroup<any>) => {
                const last = array[array.length - 1] || {
                  combined: null,
                  originals: [],
                };
                const original = last.originals[0];

                if (original && this._equalOpeningDayValue(original.value, openingDay.value)) {
                  const days = last.combined.get('days');
                  days.setValue([...days.value, openingDay.value.day]);
                  last.originals.push(openingDay);
                } else {
                  const openingDates = openingDay.get('ranges');
                  const ranges = createRangesForm(this._locale$.value, openingDates.value.length);
                  ranges.setValue(openingDates.value);

                  array.push({
                    combined: new FormGroup({
                      days: new FormControl([openingDay.value.day]),
                      open: new FormControl(openingDay.value.open),
                      ranges,
                      text: new FormControl(openingDay.value.text),
                    }),
                    originals: [openingDay],
                  });
                }
                return array;
              },
              <{combined: FormGroup<any>; originals: FormGroup<any>[]}[]>[]
            );

            this.combinedOpeningDaysSubscriptions.unsubscribe();
            this.combinedOpeningDaysSubscriptions = new Subscription();

            for (const combinedOpeningDay of this.combinedOpeningDays) {
              this.combinedOpeningDaysSubscriptions.add(
                combinedOpeningDay.combined.valueChanges.subscribe((value) => {
                  for (const original of combinedOpeningDay.originals) {
                    original.setValue({
                      day: original.value.day,
                      open: value.open,
                      ranges: value.ranges,
                      text: value.text,
                    });
                  }
                })
              );

              this.combinedOpeningDaysSubscriptions.add(
                combinedOpeningDay.combined.touch$.subscribe((touched) => {
                  if (touched) {
                    this.form.markAsTouched();
                  }
                })
              );
            }

            const control = this.combinedOpeningDays[0].originals[0].get('day');

            this._disableChanges(control.disabled);
            control.registerOnDisabledChange((disabled: boolean) => {
              this._disableChanges(disabled);
            });
          }
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this._resize.register(this, this._elementRef, this._changeDetector);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.combinedOpeningDaysSubscriptions.unsubscribe();
  }

  private _disableChanges(isDisabled: boolean) {
    for (const combinedOpeningDay of this.combinedOpeningDays) {
      if (isDisabled) {
        combinedOpeningDay.combined.disable({emitEvent: false});
      } else {
        combinedOpeningDay.combined.enable({emitEvent: false});
      }
    }
  }

  get openingDaysForm(): FormArray<OpeningDay> {
    return <FormArray<OpeningDay>>this.form.get('openingDays');
  }

  _add(group: FormGroup<any>) {
    const combinedOpeningDay = this._findCombinedOpeningDay(group);

    for (const original of combinedOpeningDay?.originals || []) {
      original.get('ranges').push(createRangeForm());
    }

    this.add.next(group);
  }

  _remove({group, range}: {group: FormGroup<any>; range: FormGroup<any>}) {
    const combinedOpeningDay = this._findCombinedOpeningDay(group);

    for (const original of combinedOpeningDay?.originals || []) {
      const ranges = original.get('ranges');
      const index = ranges.controls.findIndex((r: AbstractControl) => r === range);
      if (ranges.length > 1) {
        ranges.removeAt(index);
      }
    }

    this.remove.next({group, range});
  }

  private _findCombinedOpeningDay(group: FormGroup<any>) {
    return this.combinedOpeningDays.find((od) => od.combined === group);
  }

  private _equalOpeningDayValue(openingDay1: any, openingDay2: any) {
    return (
      openingDay1.open === openingDay2.open &&
      openingDay1.text === openingDay2.text &&
      JSON.stringify(openingDay1.ranges) === JSON.stringify(openingDay2.ranges)
    );
  }
}
