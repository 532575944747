<div class="label">
  <ax-opening-hours-date-view
    class="date"
    [dates]="datesForm.value"
    [text]="firstText"
  ></ax-opening-hours-date-view>
  <button *ngIf="special" axButton icon-button (click)="editClicked.emit()">
    <ax-icon class="icon" name="pencil"></ax-icon>
  </button>
</div>

<button
  *ngIf="openingDaysForm.controls.length > 1"
  class="dropdown"
  axButton
  type="button"
  icon-button
  (click)="toggle()"
>
  <ax-icon class="icon" [name]="collapsed ? 'chevron-right' : 'chevron-down'"></ax-icon>
</button>

<ax-days-combined-edit
  [name]="name"
  class="day"
  *ngIf="collapsed"
  [form]="form"
  [cleared]="cleared"
  [readonly]="readonly"
  (add)="add($event)"
  (remove)="remove($event)"
></ax-days-combined-edit>

<div class="days" *ngIf="!collapsed">
  <ax-day-edit
    *ngFor="let dayGroup of openingDaysForm.controls; let index = index"
    [name]="name + ' day' + index"
    [group]="dayGroup"
    [cleared]="cleared"
    [readonly]="readonly"
    (add)="add($event)"
    (remove)="remove($event)"
  ></ax-day-edit>
</div>

<div class="errors">
  <div class="c-property-edit__error" *ngFor="let error of openingDaysForm?.errors | keyvalue">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{ error.key | errorTranslationKey | translate: error.value }}</span>
  </div>
</div>
