/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {hasValue, isOverflowing} from '@aztrix/helpers';
import {Property, PropertyType} from '@aztrix/models';
import {ProfilePropertyRepresentation, PropertyRepresentation} from '@aztrix/sdk';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Subscription} from 'rxjs';

let nextUniqueId = 0;

@Directive()
export abstract class AbstractPropertyEdit implements OnInit, OnChanges, OnDestroy {
  @Input() form?: FormGroup<ControlsOf<Property | PropertyRepresentation>> | any | null;
  @Input() name?: string;
  @Input() hint?: string;
  @Input() autofocus?: boolean;
  @Input() customLabel? = '';
  @Input() required = false;
  @Input() showErrors = true;
  @Input() showIcon = false;

  @Input() autocompleteAttr?: string = undefined;
  @Input() autocompleteProperties?: (Property | ProfilePropertyRepresentation)[];
  @Input() autocomplete = false;

  @Input() prefix?: string;
  @Input() postfix?: string;
  @Input() readonly = false;
  @Input() enableValidation = true;
  @Input() icon?: string;

  @ViewChild('hintElement') hintElement: ElementRef;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;
  collapsed = false;
  overflowing = false;

  private _subscription = new Subscription();

  protected uniqueId = nextUniqueId++;
  protected disabledChangesFn: (disabled: boolean) => unknown;

  ngOnInit() {
    this._subscription.add(
      this.form.touch$?.subscribe(() => {
        this.form.markAllAsTouched();
        for (const control of this.propertiesControl?.controls || []) {
          control.get('value')?.updateValueAndValidity();
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      if (this.disabledChangesFn) {
        this.disabledChangesFn(this.form.disabled);
      }

      let valueControl = this.valueControl;
      if (this.propertiesControl?.length) {
        const control = this.propertiesControl.controls[0];
        if (control) {
          valueControl = <FormControl<string>>control.get('value');
        }
      }

      if (valueControl) {
        valueControl.registerOnDisabledChange((isDisabled) => {
          if (this.disabledChangesFn) {
            this.disabledChangesFn(isDisabled);
          }
        });
      }
    }
  }

  registerOnDisabledChange(fn: (isDisabled: boolean) => void) {
    this.disabledChangesFn = fn;
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  getName() {
    return [this.name, this.form?.value.type, this.uniqueId].filter((v) => !!v).join(' ');
  }

  get valueControl(): FormControl<string | undefined> {
    return <FormControl<string>>this.form?.get('value');
  }

  get typeControl(): FormControl<PropertyType> {
    return <FormControl<PropertyType>>this.form?.get('type');
  }

  get type(): PropertyType {
    return this.typeControl?.value;
  }

  get propertiesControl(): FormArray<Property> {
    return <FormArray<Property>>this.form?.get('properties');
  }

  get hasValue() {
    return hasValue(this.form?.value);
  }

  protected markAsDirty() {
    if (!this.form?.dirty) {
      this.form.markAsDirty();
    }
  }

  protected getPropertyControl(type: PropertyType): FormGroup<ControlsOf<Property>> {
    return <FormGroup<ControlsOf<Property>>>(
      this.propertiesControl.controls.find(
        (propertyControl) => propertyControl.value?.type === type
      )
    );
  }

  isOverflowing() {
    if (!this.overflowing) {
      this.overflowing = isOverflowing(this.hintElement);
      return this.overflowing;
    }

    return this.overflowing;
  }
}
