<ax-opening-hours-day-view class="label" [days]="days"></ax-opening-hours-day-view>
<ng-container [formGroup]="group">
  <mat-slide-toggle
    class="check"
    [ngClass]="{readonly: readonly}"
    formControlName="open"
  ></mat-slide-toggle>
</ng-container>

<ng-container *ngIf="open$ | async">
  <div class="ranges">
    <div class="range" *ngFor="let range of rangeControls; let index = index">
      <ax-time-range-edit
        [name]="name + ' time-range' + index"
        [group]="range"
        [readonly]="readonly"
      ></ax-time-range-edit>
      <button
        axButton
        type="button"
        icon-button
        *ngIf="removeable() && !group.disabled && !readonly"
        (click)="remove.next({group: group, range: range})"
      >
        <ax-icon class="icon" name="close"></ax-icon>
      </button>
      <div class="errors" *ngIf="range?.get('from')?.touched && range?.get('to')?.touched">
        <div class="c-property-edit__error" *ngFor="let error of range.errors | keyvalue">
          <ax-icon class="icon" name="alert-circle"></ax-icon>
          <span>{{ error.key | errorTranslationKey | translate: error.value }}</span>
        </div>
      </div>
    </div>

    <div class="errors">
      <div class="c-property-edit__error" *ngFor="let error of ranges?.errors | keyvalue">
        <ax-icon class="icon" name="alert-circle"></ax-icon>
        <span>{{ error.key | errorTranslationKey | translate: error.value }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(open$ | async) === false || (open$ | async) === null">
  <span *ngIf="cleared" class="closed"> {{ 'property.value.empty' | translate }}</span>
  <span *ngIf="!cleared" class="closed">{{ 'label.closed' | translate | uppercase }}</span>
</ng-container>

<button
  class="add"
  *ngIf="(open$ | async) === true && !group.disabled; else dummy"
  axButton
  type="button"
  icon-button
  color="primary"
  [disabled]="readonly"
  outline
  (click)="add.next(group)"
>
  <ax-icon class="icon" name="plus"></ax-icon>
</button>

<ng-template #dummy>
  <p *ngIf="medium">&nbsp;</p>
</ng-template>
