import {Pipe, PipeTransform} from '@angular/core';
import {ProposalRepresentation} from '@aztrix/sdk';

@Pipe({
  name: 'proposalById',
})
export class ProposalByIdPipe implements PipeTransform {
  transform(
    proposals: ProposalRepresentation[] | undefined,
    proposalId: string
  ): ProposalRepresentation | undefined {
    return proposals?.find((p) => p.id === proposalId);
  }
}
