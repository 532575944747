import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {createRangeForm} from '@aztrix/helpers';
import {FormArray, FormGroup} from '@ngneat/reactive-forms';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';
@Component({
  selector: 'ax-day-range-edit',
  templateUrl: 'day-range-edit.component.html',
  styleUrls: ['day-range-edit.component.scss'],
})
export class DayRangeEditComponent {
  @Input() form: any;
  @Input() name: string;
  @Input() special: boolean;
  @Input() cleared = false;
  @Input() readonly = false;
  @Output() editClicked = new EventEmitter();

  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @MediaQuery('(min-width: 39.375em)') @HostBinding('class.large') large = false;

  constructor(
    resize: ObserveResizeService,
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef
  ) {
    resize.register(this, elementRef, changeDetector);
  }

  add(group: UntypedFormGroup) {
    (<UntypedFormArray>group.get('ranges')).push(createRangeForm());
  }

  remove({group, range}: {group: UntypedFormGroup; range: UntypedFormGroup}) {
    const ranges = <UntypedFormArray>group.get('ranges');
    if (ranges.length < 2) {
      const r = ranges.controls[0];
      r.setValue({from: new Date(1970, 0, 1, 9), to: new Date(1970, 0, 1, 17)});

      group.get('open')?.setValue(false);
    } else {
      const index = ranges.controls.findIndex((r) => r === range);
      ranges.removeAt(index);
    }
  }

  get datesForm(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('dates');
  }

  get openingDaysForm(): FormArray<FormGroup<any>> {
    return <FormArray<FormGroup<any>>>this.form.get('openingDays');
  }

  get collapsed(): boolean {
    return this.form.get('collapsed')?.value;
  }

  get firstText(): string {
    const texts = this.openingDaysForm.controls.map((g) => <FormControl>g.get('text'));
    return texts.length ? texts[0]?.value : '';
  }

  toggle() {
    this.form.get('collapsed')?.setValue(!this.collapsed);
  }
}
