<mat-form-field
  appearance="outline"
  class="c-input remove-subtext"
  [ngClass]="{readonly: readonly}"
  floatLabel="always"
>
  <mat-label>{{ label }}</mat-label>
  <ax-time-input [name]="name" autocomplete="off" [formControl]="control"></ax-time-input>
  @for (error of control.errors | keyvalue; track error.key) {
    <mat-error>{{ error.key | errorTranslationKey | translate: error.value }}</mat-error>
  }
</mat-form-field>
