import {Component, HostBinding, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'ax-time-edit',
  templateUrl: 'time-edit.component.html',
  styleUrls: ['time-edit.component.scss'],
})
export class TimeEditComponent {
  @HostBinding('class.c-property-edit') propertyEditClass = true;

  @Input() control: FormControl<any>;
  @Input() name: string;
  @Input() label: string;
  @Input() readonly = false;
}
