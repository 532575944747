import {Component, Input} from '@angular/core';
import {fuzzySort, getLatestLanguage} from '@aztrix/helpers';
import {ProposalRepresentation} from '@aztrix/sdk';
import {Observable, of} from 'rxjs';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-proposal-select-search',
  templateUrl: './proposal-select-search.component.html',
})
export class ProposalSelectSearchComponent extends AbstractSelectSearch {
  @Input() proposals: ProposalRepresentation[];
  @Input() languageCode: string;

  searchCallback$ = (
    options: ProposalRepresentation[],
    query: string
  ): Observable<(ProposalRepresentation | undefined)[]> => {
    const searchOptions = options.map((proposal) => ({
      id: proposal.id,
      name: getLatestLanguage(proposal, this.languageCode)?.name,
    }));

    return of(
      fuzzySort(searchOptions, query, ['name']).map((result) =>
        options.find((option) => option.id === result.id)
      )
    );
  };
}
